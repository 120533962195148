<template>
  <v-pagination
    :length="length"
    :total-visible="totalVisible"
    :value="selectedPage"
    circle
    @input="onClick"
  />
</template>

<script>
export default {
  name: 'BasePagination',
  props: {
    length: {
      type: Number,
      default: 1
    },
    selectedPage: {
      type: Number,
      default: 1
    },
    totalVisible: {
      type: Number,
      default: 1
    }
  },
  methods: {
    onClick(e) {
      this.$vuetify.goTo(0, { duration: 0 });
      this.$emit('input', e);
    }
  }
};
</script>
