<template>
  <v-dialog
    v-model="show"
    persistent
    max-width="600px"
  >
    <template
      v-slot:activator="{ on, attrs }"
    >
      <v-btn
        class="small-outset rounded-xl"
        color="primary"
        fixed
        style="top: 150px; z-index: 10;"
        left
        dark
        v-bind="attrs"
        v-on="on"
      >
        <v-icon left> mdi-filter </v-icon>
        検索条件を表示
      </v-btn>
    </template>
    <v-card :style="`border: solid ${$vuetify.theme.themes.light.primary}; border-width: 0 7px 7px 7px;`" tile>
      <v-form
        ref="form"
        v-model="valid"
        @submit.prevent
      >
        <v-card-title class="primary justify-left white--text font-weight-bold py-1" style="height: 50px;">
          <span class="text-h5 font-weight-bold">検索条件</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                md="12"
              >
                <base-text-field
                  :counter="50"
                  :label="'キーワード'"
                  :show-help-icon="true"
                  :help-tooltip="'入力したキーワードをタイトルまたは依頼内容に含むクエストを表示します'"
                  :rules="[maxLength(50)]"
                  :value="inputKeyword"
                  @input="onInput({ inputKeyword: $event })"
                />
              </v-col>

              <v-col
                cols="12"
                md="6"
              >
                <base-select
                  :items="departments"
                  :item-text="'name'"
                  :item-value="'id'"
                  :label="'クエスト発行部署'"
                  :value="selectedDepartment"
                  @input="onInput({ selectedDepartment: $event })"
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <base-select
                  :items="ordering"
                  :item-text="'name'"
                  :item-value="'id'"
                  :label="'並び順'"
                  :value="selectedOrdering"
                  @input="onInput({ selectedOrdering: $event })"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="grey"
            text
            @click="show = false"
          >
            閉じる
          </v-btn>
          <v-btn
            color="primary"
            text
            :disabled="!valid"
            type="submit"
            @click="onClick"
          >
            この条件で絞り込む
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import BaseSelect from '@/components/atoms/BaseSelect';
import BaseTextField from '@/components/atoms/BaseTextField';
import FormRulesMixin from '@/mixins/FormRulesMixin';

export default {
  name: 'SearchQuestConditionModal',
  components: { BaseSelect, BaseTextField },
  mixins: [FormRulesMixin],
  props: {
    departments: {
      type: Array,
      default: () => []
    },
    inputKeyword: {
      type: String,
      default: ''
    },
    selectedDepartment: {
      type: [Number, String],
      default: null
    },
    ordering: {
      type: Array,
      default: () => []
    },
    selectedOrdering: {
      type: String,
      default: ''
    },
    showModal: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      valid: true
    };
  },
  computed: {
    show: {
      get() {
        return this.showModal;
      },
      set(val) {
        this.$emit('on-show-modal', val);
      }
    }
  },
  methods: {
    onClick(e) {
      this.$emit('on-click', e);
    },
    onInput(e) {
      this.$emit('on-input', e);
    }
  }
};
</script>
