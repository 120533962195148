<!-- クエストを探す -->
<template>
  <div>
    <v-container
      class="lighten-5 mb-6 mt-10"
      :class="$vuetify.breakpoint.xsOnly ? 'mt-15': ''"
      :style="styles.app"
      fluid
    >
      <v-row>
        <v-col
          cols="12"
          md="12"
        >
          <v-row justify="center">
            <template v-if="searchLoader">
              <v-col class="text-center">
                <v-progress-circular
                  :size="200"
                  :width="10"
                  color="primary"
                  indeterminate
                />
              </v-col>
            </template>
            <template v-else-if="!searchLoader && quests.results.length === 0">
              <v-col class="text-center">
                <v-icon large color="yellow darken-4">mdi-alert-decagram</v-icon><span class="font-weight-bold">{{ emptyResultMessage }}</span>
              </v-col>
            </template>
            <template v-else>
              <v-col cols="12" md="12">
                <!-- ページネータ（上） -->
                <base-pagination
                  :length="quests.length"
                  :selected-page="selectedPage"
                  :total-visible="5"
                  @input="onPageClick"
                />
              </v-col>
              <v-col
                v-for="quest in quests.results"
                class="mx-4 mb-4"
                :key="quest.uuid"
                cols="auto"
              >
                <v-hover>
                  <quest-sticky-note
                    :hover="true"
                    :note-color="$route.name === 'SearchWaitingQuest' ? 'note-yellow' : 'note-blue'"
                    :quest="quest"
                    @on-show-public-profile="onSelectedClient(quest.client)"
                    @go-to-detail="onGoToDetail(quest.uuid)"
                  />
                </v-hover>
              </v-col>
              <v-col cols="12" md="12">
                <!-- ページネータ（下） -->
                <base-pagination
                  :length="quests.length"
                  :selected-page="selectedPage"
                  :total-visible="5"
                  @input="onPageClick"
                />
              </v-col>
            </template>
          </v-row>
        </v-col>
      </v-row>
    </v-container>

    <!-- 検索条件 -->
    <search-quest-condition-modal
      v-if="!waitingOnly"
      :departments="departments"
      :ordering="ordering"
      :input-keyword="inputKeyword"
      :selected-department="selectedDepartment"
      :selected-ordering="selectedOrdering"
      :show-modal="showConditionModal"
      @on-click="onSearch(true)"
      @on-input="onInput"
      @on-show-modal="onShowConditionModal"
    />

    <!-- 公開プロフィールモーダル -->
    <public-profile-modal
      :show="showPublicProfile"
      :user-info="selectedClient"
      @cancel="onShowPublicProfile"
    />
  </div>
</template>

<script>
import AppMethodsMixin from '@/mixins/AppMethodsMixin';
import BasePagination from '@/components/atoms/BasePagination';
import AppStyleMixin from '@/mixins/AppStyleMixin';
import PublicProfileModal from '@/components/organisms/PublicProfileModal';
import QuestStickyNote from '@/components/organisms/QuestStickyNote';
import SearchQuestConditionModal from '@/components/organisms/SearchQuestConditionModal';

export default {
  name: 'Index',
  components: {
    BasePagination,
    QuestStickyNote,
    PublicProfileModal,
    SearchQuestConditionModal
  },
  mixins: [AppMethodsMixin, AppStyleMixin],
  props: {
    waitingOnly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      emptyResultMessage: '募集中のクエストがありません',
      quests: {
        results: []
      },
      queryParams: [],
      inputKeyword: '',
      selectedDepartment: '',
      departments: [],
      selectedOrdering: '',
      ordering: [
        { id: '', name: '---' },
        { id: '-reward', name: '報酬が高い順' },
        { id: 'deadline', name: '応募期限が近い順' }
      ],
      selectedPage: 1,
      searchLoader: false,
      showConditionModal: false,
      selectedClient: null,
      showPublicProfile: false
    };
  },
  methods: {
    onInput (value) {
      const key = Object.keys(value)[0];
      this[key] = value[key];
    },
    // クエスト詳細へ
    onGoToDetail(questUuid) {
      this.$router.push({ path: questUuid, append: true });
    },
    // 検索実行
    async onSearch(exec) {
      this.searchLoader = true;

      // URLにクエリパラメータ追加
      let queryParams = [];
      if (this.selectedPage && exec) {
        this.selectedPage = 1; // 検索実行時はページングを1に戻す
        queryParams.push(`page=${this.selectedPage}`);
      } else {
        queryParams.push(`page=${this.selectedPage}`);
      }
      if (this.inputKeyword) {
        queryParams.push(`keyword=${this.inputKeyword}`);
      }
      if (this.selectedDepartment) {
        queryParams.push(`department_id=${this.selectedDepartment}`);
      }
      if (this.selectedOrdering) {
        queryParams.push(`ordering=${this.selectedOrdering}`);
      }

      // 「承認待ちクエスト」画面の場合のクエリパラメータ追加
      if (this.waitingOnly) {
        this.emptyResultMessage = '承認待ちのクエストはありません';
        queryParams.push('waiting_only=1');
      }

      // 検索実行
      const queryString = queryParams.length > 0 ? `?${queryParams.join('&')}` : '';
      if (exec) { 
        history.pushState(null, null, queryString); // 検索ボタン押下時は履歴に残す
        this.emptyResultMessage = '検索条件に合致するクエストが見つかりませんでした';
      } else {
        history.replaceState(null, null, queryString); // 初期表示時は履歴に残さない
      }
      try {
        this.quests = await this.$store.dispatch('quests/list', queryString);
        this.selectedPage = this.quests.currentPage;
      } finally {
        this.searchLoader = false;
        this.onShowConditionModal(false);
      }
    },
    // 検索条件用に部署を取得
    async getDepartments() {
      this.departments = await this.$store.dispatch('departments/list');
      this.departments.unshift({ id: '', name: '---' });
    },
    // クエリパラメータを取り出す（ブラウザバックで戻ってきた場合とか）
    parseQueryParams() {
      // ページ数
      if (this.$route.query['page']) {
        this.selectedPage = Number(this.$route.query['page']);
      }

      // キーワード
      if (this.$route.query['keyword']) {
        this.inputKeyword = this.$route.query['keyword'];
      }

      // 部署
      if (this.$route.query['department_id']) {
        this.selectedDepartment = Number(this.$route.query['department_id']);
      }

      // 並び順
      if (this.$route.query['ordering']) {
        this.selectedOrdering = this.$route.query['ordering'];
      }
    },
    onPageClick(page) {
      this.selectedPage = page;
      this.onSearch(); // クエスト取得
    },
    onShowConditionModal(val) {
      this.showConditionModal = val;
    },
    /** 発注者名クリック時の処理 */
    onSelectedClient(selectedClient) {
      this.selectedClient = selectedClient;
      this.onShowPublicProfile();
    },
    /** 発注者プロフィールをモーダル表示 */
    onShowPublicProfile() {
      this.showPublicProfile = !this.showPublicProfile;
    }
  },

  async mounted() {
    this.showLoadingOverlay(async () => {
      this.getDepartments();
      this.parseQueryParams(); // クエリパラメータを取り出す（ブラウザバックされた時などを考慮）
      await this.onSearch(); // クエスト取得
    });
  }
};
</script>
