<!-- 単一選択のコンポーネント -->
<template>
  <v-select
    :append-icon="appendIcon"
    :append-outer-icon="appendOuterIcon"
    :autofocus="autofocus"
    :background-color="backgroundColor"
    :class="className"
    :counter="counter"
    :counter-value="counterValue"
    :dark="dark"
    :dense="dense"
    :disabled="disabled"
    :label="label"
    :items="items"
    :item-text="itemText"
    :item-value="itemValue"
    :outlined="outlined"
    :single-line="singleLine"
    :value="value"
    @input="onClick"
  />
</template>

<script>
export default {
  name: 'BaseSelect',
  props: {
    appendIcon: {
      type: String,
      default: '$dropdown'
    },
    appendOuterIcon: {
      type: String,
      default: ''
    },
    autofocus: {
      type: Boolean,
      default: false
    },
    backgroundColor: {
      type: String,
      default: ''
    },
    className: {
      type: String,
      default: ''
    },
    counter: {
      type: [Boolean, Number, String],
      default: undefined
    },
    counterValue: {
      type: Function,
      default: null
    },
    dark: {
      type: Boolean,
      default: false
    },
    dense: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    items: {
      type: [Array],
      default: () => []
    },
    itemText: {
      type: String,
      default: ''
    },
    itemValue: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    outlined: {
      type: Boolean,
      default: true
    },
    singleLine: {
      type: Boolean,
      default: false
    },
    value: {
      type: [String, Number],
      default: null
    }
  },
  methods: {
    onClick(e) {
      this.$emit('input', e);
    }
  }
};
</script>
